import { Component, Input } from '@angular/core';
import { StreamingPlatform } from '@usheru-hff/shared/domain';

/**
 * Displays the a stream platform name.
 *
 * //TODO barbeitoalex propostal - why not display the image if it is too and refractor to "streaming-logo"?
 */
@Component({
  selector: 'ush-streaming-logo-fallback',
  templateUrl: './streaming-logo-fallback.component.html',
  styleUrls: ['./streaming-logo-fallback.component.scss']
})
export class StreamingLogoFallbackComponent {
  /**
   * @docsNotRequired
   */
  @Input() streamingPlatform: StreamingPlatform;
}
