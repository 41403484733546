import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StreamingLogoFallbackComponent } from './streaming-logo-fallback/streaming-logo-fallback.component';

@NgModule({
  imports: [CommonModule],
  declarations: [StreamingLogoFallbackComponent],
  exports: [StreamingLogoFallbackComponent]
})
export class SharedUiStreamingLogoFallbackModule {}
